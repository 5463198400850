<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Feedback</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <form @submit.prevent="patchTestimony" class="row p-2">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row p-auto">
                                            <div class="col-lg-12 mt-4">
                                                <div class="row">
                                                    <div class="form-group col-md-12">
                                                        <label for="">Name</label>
                                                        <input type="text" class="form-control" v-model="form.name" placeholder="Insert name" required/>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="">Email</label>
                                                        <input type="email" class="form-control" v-model="form.email" placeholder="Insert email" required/>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="">Job</label>
                                                        <input type="Text" class="form-control" v-model="form.job" placeholder="Insert job" required/>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="">Phone</label>
                                                        <input type="number" class="form-control" v-model="form.phone" placeholder="Insert phone" required/>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="">Address</label>
                                                        <textarea class="form-control" v-model="form.address" placeholder="Insert address" required></textarea>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="">Testimony</label>
                                                        <textarea class="form-control" v-model="form.text" placeholder="Insert testimony" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 text-right">
                                                <button type="submit" class="btn btn-current">
                                                Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            idTestimony: this.$route.params.idTestimony,
            isLoad: true,
            form : {
                id: '',
                name: '',
                email: '',
                phone: '',
                job: '',
                address: '',
                text: '',
            }
        }
    },
    created() {
        this.getTestimony()
    },
    methods: {
        async getTestimony(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/events/testimony/detail?id=${this.idTestimony}`, {
                headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.form = res.data.data
                }
            }).catch((err) => console.error(err.response)).finally(() => this.isLoad = false)
        },
        async patchTestimony() {
            var data = this.form
            await axios.patch(`${process.env.VUE_APP_URL_API}/admin/events/testimony`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Testimony',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'EventTestimonyAdmin' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Testimony',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>
